.eit {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* position: relative; */
  /* gap: 5rem; */
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  margin-right: 10px;
}

.title {
  font-size: 1.5rem;
  font-weight: 200;
  position: relative;
}
.items {
  display: flex;
  gap: 1rem;
  position: absolute;
  left: 15rem;
}
.item {
  font-family: "Roboto Mono", monospace;
  background-color: rgb(214, 214, 214);
  padding: 0.7rem;
  border-radius: 8px;
  margin-right: 5px;
}
@media only screen and (max-width: 1070px) {
  #itemLong {
    min-width: 330px;
  }

  .item {
    font-size: 0.8rem;
  }

  @media only screen and (max-width: 768px) {
    .eit {
      flex-direction: column;
      align-items: flex-start;
      gap: 0.5rem;
      max-width: 350px;
    }
    .items {
      display: block;
      position: relative;
      gap: 0.5rem;
      max-width: 350px;
      left: 0%;
    }
    .title {
      font-size: 18px;
    }

    .item {
      display: inline-block;
      margin-top: 10px;
    }
  }
}
