.a {
  height: 100vh;
  display: flex;
  align-items: center;
  background-color: #eeee;
  min-height: fit-content;
  max-height: fit-content;
}

.a-left {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100%;
}

.a-right {
  flex: 1;
}

.a-card.bg {
  position: absolute;
  top: 50px;
  left: 50px;
  background-color: #59b256;
}

.a-card {
  width: 60%;
  height: 70vh;
  border-radius: 30px;
  position: relative;
  overflow: hidden;
}

.a-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.a-title {
  font-weight: 400;
}

.a-sub {
  margin: 20px 0px;
}

.a-desc {
  font-weight: 300;
}

.a-sub, .a-desc {
  padding-right: 100px;
}

.a-desc a {
  text-decoration: underline;
}

@media screen and (max-width: 600px) {
  .a {
    flex-direction: column;
    text-align: center;
    max-width: 100%;
    height: auto;
  }

  .a-left {
    width: 100%;
    margin-top: 1rem;
  }

  .a-card {
    height: 30vh;
  }

  .a-card.bg,
  .a-award {
    display: none;
  }

  .a-right {
    padding: 20px;
  }

  .a-sub, .a-desc {
    padding-right: 0px;
  }
}
