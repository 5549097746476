.f {
  background-color: #eeee;
  display: flex;
  gap: 3rem;
  padding: 20px;
}

.f-collumn {
  flex: 2;
}
.f-col2,
.f-col3,
.f-col4 {
  flex: 1;
}
.f-collumn-title {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
}
.copyright {
  padding-left: 20px;
  background-color: white;
  text-align: left;
  padding: 1rem 20px;
}

a {
  text-decoration: none;
  color: black;
}

@media only screen and (max-width: 768px) {
  .f {
    flex-direction: column;
    gap: 0rem;
  }
  .f-collumn {
    max-width: 100%;
  }
  .copyright {
    margin-top: 0;
  }

  .f-collumn-title {
    font-size: 2rem;
  }

  .f-desc {
    font-size: 0.9rem;
  }
}
