.heading {
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
}

ul {
  padding-left: 0;
}

li {
  margin-bottom: 1rem;
  list-style-type: none;
}
a {
  font-size: 1.1rem;
  color: black;
}
