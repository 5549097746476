.e {
  height: 100vh;
  display: flex;
  align-items: center;
  max-height: fit-content;
  min-height: fit-content;
}

.e-left {
  flex: 1;
  margin-left: 20px;
  width: max-content;
}

/* .e-right {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: center;
  justify-self: center;
  position: relative;
  height: 100%;
} */

/* .e-card.bg {
  position: absolute;
  top: 50px;
  right: 50px;
  background-color: #59b256;
}

.e-card {
  width: 60%;
  height: 70vh;
  border-radius: 30px;
  position: relative;
  overflow: hidden;
}

.e-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
} */

.e-title {
  font-weight: 400;
}

.e-desc {
  font-weight: 300;
  padding-bottom: 10px;
}

.info-heading {
  margin-top: 20px;
  font-weight: 300;
  font-size: 2rem;
  text-transform: uppercase;
}

.dCV {
  border: none;
  padding: 15px;
  background-color: #59b256;
  color: white;
  font-weight: 500;
  cursor: pointer;
}

.dCV a {
  color: white;
  text-decoration: none;
  font-size: 25px;
}

@media only screen and (max-width: 1240px) {
  .e-card.bg {
    display: none;
  }

  .e-right {
    margin-right: 20px;
    justify-content: right;
  }

  @media only screen and (max-width: 1070px) {
    .e-card {
      display: none;
    }

    @media only screen and (max-width: 768px) {
      .e {
        height: auto;
        margin-top: 1rem;
      }

      .info-heading {
        font-size: 20px;
      }

      .e-desc {
        max-width: 350px;
      }

      .e-right {
        display: none;
      }

      .info-item {
        max-width: 350px;
      }

      .dCV {
        margin-bottom: 1rem;
      }
    }
  }
}
