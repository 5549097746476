.para {
  max-width: 500px;
  margin: 0 auto;
  font-size: 1.3rem;
  line-height: 1.3em;
}

@media only screen and (max-width: 1400px) {
  .para {
    font-size: 1.1rem;
  }
}
